@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 100px;
    justify-content: flex-start;
    align-items: center;
    border-top: 2px solid $primary;
    background-color: $light0;
}