@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
@import "@dex/bubl-web/src/styles/Web.scss";
@import "./Fonts.scss";

html {
    font-size: 8px;
    overflow-y: scroll;
    scroll-behavior: smooth;

    @media #{$tablet-portrait-up} {
        font-size: 8px;
    }

    @media #{$tablet-landscape-up} {
        font-size: 8px;
    }

    @media #{$laptop-up} {
        font-size: 10px;
    }

    @media #{$desktop-up} {
        font-size: 10px;
    }
}

body {
    @include bodyText(('line-height': null));
}

.container {
    width: 1400px;
    max-width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}

.container-fluid {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}

img.loaded {
    background-color: transparent !important;
}
