@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.wrap {}


.label {
    @include bodyText(("size":$s, "weight": 500, 'spacing': 1px));
    padding-left: 2px;
    padding-bottom: 10px;
    display: block;
}

.label :global .form-field-label-required {
    color: $redHl;
}

.label :global .form-field-label-required:before {
    content: " ";
}

.input {
    @include bodyText();
    display: block;
    box-sizing: border-box;
    padding: 1rem 1.5rem;
    height: 52px;
    width: 100%;
    background: $light1;
    border: 1px solid $light3;
    border-radius: 5px;
    transition: .3s ease all;
}


.input:focus {
    border: 1px solid $primary;
    background: $light0;
    box-shadow: 2px 0 8px rgba($primary, .5);
}

.error {
    @include bodyText();
    color: $redHl;
}

:global .form-field {
    width: 100%
}