@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.formWrapper {
    max-width: 350px;
    padding: 10px;
}

.success {
    @include bodyText(("color": rgba($dark0, .7), "size": $l, "line-height": 1.5));
    background-color: rgba($primary, .15);
    display: block;
    max-width: 330px;
    width: 100%;
    padding: 15px 10px;
    text-align: center;
    border-radius: 5px;
    border: 1px solid $primary;
}

.subHeading :global a {
    color: $dark0;

}